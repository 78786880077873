import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe les composants
import Header from '../components/header'
import Footer from '../components/footer'
import Formulaire from '../components/formulaire'

//On importe le CSS
import '../style/mentions-legales.scss'

export default function MentionsLegales() {

    const Meta = {
        title: "Mentions légales de Minucci.fr",
        description: "Retrouvez toutes les informations légales pour contacter la S.A.S. Minucci.",
        canonical: "https://minucci.fr/mentions-legales"
    }


    return (
        <HelmetProvider>
            <Helmet>
                {/* A reprendre sur toutes les pages */}
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <link rel="icon" href={Favicon} />

                {/* A personnaliser sur toutes les pages */}
                <title>{Meta.title}</title>
                <meta name="description" content={Meta.description} />
                <link rel="canonical" href={Meta.canonical} />
            </Helmet>

            <div className="BackGroundIndex">

                <Header />

                <div className="MainMentionsLegales">

                    <h1 align="center">MENTIONS LEGALES</h1>

                    <p>Le présent site internet est édité par la S.A.S. MINUCCI dont le siège est sis 22 rue Delaporte à Mondrepuis (france), prise en la personne de son représentant légal, Monsieur Bartholomé MINUCCI, Président.</p>

                    <p>Responsable de publication : Monsieur Bartholomé MINUCCI.</p>

                    <p align="justify">Le Site a été conçu et réalisé par David-Julien MINUCCI (EI), <a href="https://minuccitech.com">MINUCCI TECH</a>, Siren n°838554343</p>


                    <p>Hébergeur : GatsbyCloud, 548 Market St #36791, San Francisco, California 94104.</p>

                    <h2>OBJET DU SITE INTERNET</h2>
                    <p>Le présent site internet a pour objet la présentation de l'entreprise et de ses produits.</p>

                    <h2>DROITS D'AUTEUR</h2>
                    <p>Que ce soit la présentation visuelle, le logo, le contenu écrit, le contenu audiovisuel, les photographies, les fonctionnalités du site internet... L'ensemble de ces éléments sont protégés par le droit d'auteur et ne peuvent être reproduits sans l'accord préalable de l'entreprise.</p>

                    <h2>DONNEES A CARACTERE PERSONNEL</h2>
                    <p>Le présent site internet utilise des cookies dans le but de connaître les chiffres de fréquentation de ses différentes pages. L'objectif est de vous proposer du contenu de meilleur qualité, qui réponde parfaitement à vos attentes. Vos données personnelles sont collectées lors d'une prise de contact avec notamment le formulaire de contact, lorsque vous prenez rendez-vous. Il vous est demandé, uniquement les informations nécessaires pour pouvoir vous recontacter ou répondre à votre problématique.</p>

                    <p>Conformément au RGPD (règlement général sur la protection des données), vous disposez d’un droit d’information, d’accès, de rectification, d’effacement, de limitation, de portabilité et d’opposition sur vos données personnelles. Pour exercer ce droit, contactez-nous en nous envoyant un message via le formulaire ci-dessous.</p>

                    <Formulaire />

                </div>



            </div>

            <Footer />


        </HelmetProvider>
    )
}

